import { useLocation } from '@gatsbyjs/reach-router'
import { Link, navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CheckmarkSpotIllustration from '../assets/images/checkmark_spot_illustration.svg'
import linkedin from '../assets/images/social-icons/linkedin-black.svg'
import twitter from '../assets/images/social-icons/twitter-black.svg'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Layout from '../components/Layout'
import theme from '../theme'

type ThankYouParams = {
  component: ({
    existingSourceCodeHosting,
  }: {
    existingSourceCodeHosting: string
  }) => JSX.Element
  description: string
}

const thankyouComponents = {
  trial: {
    install: {
      component: TrialInstall,
      description: 'Connect your data to Swarmia',
    },
    'source-code-hosting-not-supported': {
      component: TrialNotSupported,
      description: 'Thanks for your interest in Swarmia',
    },
  },
  'demo-request': {
    'small-team': {
      component: SmallTeamInstall,
      description: 'Small teams use Swarmia for free',
    },
    'source-code-hosting-not-supported': {
      component: DemoNotSupported,
      description: 'Unfortunately your source code hosting is unsupported',
    },
  },
  'startup-signup': {
    qualified: {
      component: StartupQualified,
      description: 'Thanks for applying to the Swarmia Startup Program!',
    },
  },
} as const

function ThankYouPage() {
  const location = useLocation()
  const { origin, target, sch } = queryString.parse(location.search) as {
    origin: 'trial' | 'demo-request' | 'startup-signup'
    target: 'install' | 'source-code-hosting-not-supported' | 'success'
    sch: string
  }
  const [thankYou] = useState<ThankYouParams>(
    thankyouComponents[origin]?.[target],
  )

  useEffect(() => {
    if (!thankYou) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate('/demo')
    }
  }, [thankYou])

  if (!thankYou) {
    return <></>
  }

  return (
    <Layout justifyCenter title="Thank you" description={thankYou.description}>
      <CenteredContainer>
        <thankYou.component existingSourceCodeHosting={sch} />
      </CenteredContainer>
    </Layout>
  )
}

function TrialInstall() {
  return (
    <>
      <ImageContainer>
        <img src={CheckmarkSpotIllustration} alt="Success" />
      </ImageContainer>
      <h1>Thanks for signing up!</h1>
      <div className="message-content">
        <p>
          To connect your data to Swarmia, follow{' '}
          <Link
            to="https://help.swarmia.com/organization-setup"
            target="_blank"
          >
            these instructions
          </Link>{' '}
          and install Swarmia from the GitHub marketplace.{' '}
        </p>
      </div>
      <CTAElement>
        <Link to="https://github.com/marketplace/swarmia">
          <button className="big primary">Go to GitHub marketplace</button>
        </Link>
      </CTAElement>
    </>
  )
}

function TrialNotSupported({
  existingSourceCodeHosting,
}: {
  existingSourceCodeHosting: string
}) {
  return (
    <>
      <h1>Bear with us</h1>
      <div className="message-content">
        <p>
          We don’t support{' '}
          {existingSourceCodeHosting ?? 'your source code hosting platform'}{' '}
          yet. We’re constantly working on new integrations and will let you
          know once we’ve added support for yours.
        </p>
      </div>
      <h5>Follow us on social media</h5>
      <Socials>
        <li className="social-item linkedin">
          <a href="https://www.linkedin.com/company/swarmia/">
            <img src={linkedin} alt="LinkedIn Logo" />
          </a>
        </li>
        <li className="social-item twitter">
          <a href="https://twitter.com/SwarmiaHQ">
            <img src={twitter} alt="Twitter Logo" />
          </a>
        </li>
      </Socials>
      <FeaturedBlogArticles
        articles="latest"
        heading="Or read these while you wait for your integration"
      />
    </>
  )
}

function SmallTeamInstall() {
  return (
    <>
      <ImageContainer>
        <img src={CheckmarkSpotIllustration} alt="Success" />
      </ImageContainer>
      <h1>
        Small teams use Swarmia <br /> for free
      </h1>
      <div className="message-content">
        <p>
          Since there are less than 10 developers in your engineering
          organization, you qualify for the free plan of Swarmia.
        </p>
        <p>
          Follow{' '}
          <Link to="https://help.swarmia.com/organization-setup">
            these instructions
          </Link>{' '}
          to install Swarmia from the GitHub marketplace. If you have any
          questions, feel free to drop us an email at
          <br />
          <a>hello@swarmia.com.</a>
        </p>
      </div>
      <CTAElement>
        <Link to="https://github.com/marketplace/swarmia">
          <button className="big primary">Go to GitHub marketplace</button>
        </Link>
      </CTAElement>
    </>
  )
}

function DemoNotSupported({
  existingSourceCodeHosting,
}: {
  existingSourceCodeHosting: string
}) {
  return (
    <>
      <h1>Bear with us</h1>
      <div className="message-content">
        <p>
          We don’t support{' '}
          {existingSourceCodeHosting ?? 'your source code hosting platform'}{' '}
          yet. We’re constantly working on new integrations and will let you
          know once we’ve added support for yours.
        </p>
      </div>
      <h5>Follow us on social media</h5>
      <Socials>
        <li className="social-item linkedin">
          <a href="https://www.linkedin.com/company/swarmia/">
            <img src={linkedin} alt="LinkedIn Logo" />
          </a>
        </li>
        <li className="social-item twitter">
          <a href="https://twitter.com/SwarmiaHQ">
            <img src={twitter} alt="Twitter Logo" />
          </a>
        </li>
      </Socials>
      <FeaturedBlogArticles
        articles="latest"
        heading="Or read these while you wait for your integration"
      />
    </>
  )
}

function StartupQualified() {
  return (
    <>
      <ImageContainer>
        <img src={CheckmarkSpotIllustration} alt="Success" />
      </ImageContainer>
      <h1>Thank you!</h1>
      <div className="message-content">
        <p>
          We&apos;ve received your application and will review it shortly.
          You&apos;ll hear from us soon.
        </p>
      </div>
    </>
  )
}

const ImageContainer = styled.div`
  margin-top: ${theme.space[6]};
  margin-bottom: ${theme.space[6]};
`

const CenteredContainer = styled.div`
  display: grid;
  justify-content: center;
  text-align: center;
  padding-top: ${theme.space[10]};
  h1 {
    margin-bottom: ${theme.space[7]};
  }

  .message-content {
    margin: 0 auto;
    margin-bottom: ${theme.space[10]};
  }

  .message-content p {
    width: ${theme.size.small};
  }

  h5 {
    margin-bottom: ${theme.space[6]};
  }
`

const CTAElement = styled.div`
  margin-bottom: 250px;
`

const Socials = styled.ul`
  list-style: none;
  margin-bottom: ${theme.space[6]};

  li {
    margin: 0;
  }

  .social-item {
    display: inline;
    margin-right: ${theme.space[3]};
    text-align: center;
    width: 28px;
    height: 28px;

    & img {
      width: 28px;
      height: 28px;
    }
  }
`

export default ThankYouPage
